
import { Vue } from 'vue-class-component';

export default class Home extends Vue {
  private currentYear: number = (new Date()).getFullYear()

  // eslint-disable-next-line class-methods-use-this
  mounted(): void {
    window.onload = () => {
      document.body.className = '';
    };

    window.ontouchmove = () => false;

    window.onorientationchange = () => {
      document.body.scrollTop = 0;
    };
  }

  // eslint-disable-next-line class-methods-use-this
  email(): void {
    const me = 'rodrigo';
    const crabbly = window.location.hostname;
    window.location.href = `mailto:${me}@${crabbly}`;
  }
}
