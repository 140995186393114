<template>
  <div id="wrapper">
    <div id="bg"></div>
    <div id="overlay"></div>
    <div id="main">
      <!-- Header -->
      <div id="header">
        <h1>Rodrigo Vieira</h1>
        <p>Software Engineer</p>
        <nav>
          <ul>
            <li>
              <a href="https://www.twitter.com/crabbly" class="icon fa-twitter">
                <span class="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.github.com/crabbly" class="icon fa-github">
                <span class="label">Github</span>
              </a>
            </li>
            <li>
              <a
                href="https://stackoverflow.com/users/6047599/crabbly"
                class="icon fa-stack-overflow"
              >
                <span class="label">Stack Overflow</span>
              </a>
            </li>
            <li>
              <a class="icon fa-envelope-o" @click="email">
                <span class="label">Email</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <!-- Footer -->
      <footer id="footer">
        <span class="copyright">
          &copy; {{ currentYear }} Rodrigo Vieira | Crabbly.com - Software Development For The Web
        </span>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Home extends Vue {
  private currentYear: number = (new Date()).getFullYear()

  // eslint-disable-next-line class-methods-use-this
  mounted(): void {
    window.onload = () => {
      document.body.className = '';
    };

    window.ontouchmove = () => false;

    window.onorientationchange = () => {
      document.body.scrollTop = 0;
    };
  }

  // eslint-disable-next-line class-methods-use-this
  email(): void {
    const me = 'rodrigo';
    const crabbly = window.location.hostname;
    window.location.href = `mailto:${me}@${crabbly}`;
  }
}
</script>
