import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

/*
|--------------------------------------------------------------------------
| Analytics
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line import/extensions
import './libs/googleAnalytics.js';

/*
|--------------------------------------------------------------------------
| SaSS
|--------------------------------------------------------------------------
*/
import './sass/app.scss';

/*
|--------------------------------------------------------------------------
| Vue App
|--------------------------------------------------------------------------
*/
createApp(App)
  .use(store)
  .use(router)
  .mount('#app');
